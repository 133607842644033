import React, { useEffect, useReducer, useRef, useState } from "react";
import "./InterviewScreen.css";
import Navbar from "../../components/Reusable/Navbar/Navbar";
import record from "../../assets/Record Icon.png";
import next from "../../assets/next_arrow.png";
import Alert from "../../assets/Warning.png";
import { useNavigate } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import { API } from "../../global";
import { ProgressBar } from "react-loader-spinner";
import { Interweave } from "interweave";
// import {ProgressBar as ProgressBarLoading } from 'react-bootstrap/ProgressBar'
import * as faceapi from "face-api.js";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { BlobServiceClient } from "@azure/storage-blob";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useUploadQueue } from '../../providers/UploadQueueContext';
import { useSelector } from "react-redux";

const mimeType = 'video/webm; codecs="vp9,opus"';

export default function InterviewScreen() {
  const { transcript, resetTranscript } = useSpeechRecognition({});
  const navigate = useNavigate();

  const { uploadQueueRef, addToQueue, removeFromQueue } = useUploadQueue();

  const liveVideoFeed = useRef(null);
  const [stream, setStream] = useState(null);
  const [loading, setLoading] = useState(true);
  const [questionId, setQuestionId] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const increment = useRef(null);
  const mediaRecorder = useRef(null);
  const [interviewTimeLeft, setInterviewTimeLeft] = useState(1);
  const [btnDisable, setBtnDisable] = useState(true);
  // const [timeLeftbox, setTimeLeftbox] = useState(true);
  const [textInputRequired, setTextInputRequired] = useState(false);
  const [textInputAnswer, setTextInputAnswer] = useState("");
  const [uploadMessage, setUploadMessage] = useState("");


  // const maxRetries = 10;
  const uploadSizeToBeUpload = useRef(0);
  const uploadSizeUploaded = useRef(0);
  const recordingStartDateTime = useRef(new Date(Date.now()));

  const faceDetectionSetIntervalId = useRef();
  const tabDetectionSetIntervalId = useRef();
  const totalQuestions = useRef(sessionStorage.getItem("total_question"));
  // const intevrviewScheduleId = useRef(sessionStorage.getItem('interview_scheduled_id'));
  const interviewUUID = useRef(sessionStorage.getItem("interview_uuid"));
  const completedQuestionsCount = useRef(0);
  const currentQuestionNo = useRef(0);
  const currentQuestionId = useRef();
  const currentQuestionMaxDuration = useRef(0);

  const [progressBarDisabled, setProgressBarDisabled] = useState(true);
  const [nextBtnLoading, setNextBtnLoading] = useState(false);
  const [stopBtnLoading, setStopBtnLoaidng] = useState(false);
  const [themeColor, setThemeColor] = useState("");
  const faceDetection = JSON.parse(localStorage.getItem("faceDetection"));
  const tabDetection = JSON.parse(localStorage.getItem("tabDetection"));
  const nextButtonRef = useRef(null);
  const [timeElapsed, setTimeElapsed] = useState(0);


  const MAX_RETRIES = 5;
  const INITIAL_RETRY_DELAY = 1000; // 1 second

  let questionData = useSelector((state) => state.questionDetails?.questionData);
  // console.log("questionData",questionData)

  let userLog = [];
  // let mediaStream;
  let video_get = {};
  const location = useLocation();
  let timer;
  let statusTimer;

  const searchParams = new URLSearchParams(location.search);
  const qNo = searchParams.get("qno");

  useEffect(() => {
    let themeColor = localStorage.getItem("themeData");
    themeColor = JSON.parse(themeColor);
    setThemeColor(themeColor);
  }, []);

  //load onetime
  useEffect(() => {
    if (faceDetection) {
      loadModels();
    }
    // eslint-disable-next-line
  }, []);

  //loads whenever naivations changes
  useEffect(() => {
    getQuestion();
    // eslint-disable-next-line
  }, [navigate]);

  //update upload status in DB
  const updateUploadStatus = async (question_id, size, remark) => {
    const obj = {
      question_id: question_id,
      upload_status: size === 0 ? false : true,
      upload_size: size,
      upload_remark: remark
    };
    await fetch(`${API}/candidate/interview/update-upload-status`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${sessionStorage.getItem("Token_candi")}`,
      },
    }).catch(() => { });
  };
  // Function to process the upload queue
  const processUploadQueue = async () => {
    console.log(`Showing upload queue ref from processUploadQueue: ${uploadQueueRef.current.length}`);
    localStorage.setItem("totalFilesToBeUploaded", uploadQueueRef.current.length);
    let totalFilesUploaded = 0;
    for (const item of uploadQueueRef.current) {
      if (!item.processing) {
        totalFilesUploaded++
        item.processing = true;
        localStorage.setItem("totalFilesUploaded", totalFilesUploaded);
        try {
          console.log(`Upload initiated for the file: ${item.blob_name}`);
          await exponentialRetry(
            () => uploadToAzure(item.blob_file, item.blob_size, item.blob_name, item.blob_type),
            MAX_RETRIES,
            INITIAL_RETRY_DELAY
          );
          console.log("Uploading completed ...");
          await updateUploadStatus(item.question_id, item.blob_size, '');
          removeFromQueue(item);
        } catch (err) {
          console.error(`Error uploading to Azure: ${err.message}`);
          await updateUploadStatus(item.question_id, 0, 'Max retries reached. Skipping this file upload');
          removeFromQueue(item);
        }
      }
    }
  };

  //upload files to Azure Storage
  const uploadToAzure = async (blob_file, blob_size, blob_name, blob_type) => {
    const blobServiceClient = new BlobServiceClient(sessionStorage.getItem("sas_url"));
    const containerName = interviewUUID.current.toString().toLowerCase();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blob_name);

    const videoFile = new File(blob_file, blob_name, { type: blob_type });

    uploadSizeToBeUpload.current = JSON.parse(localStorage.getItem("uploadSizeToBeUpload") || "0");
    uploadSizeUploaded.current = JSON.parse(localStorage.getItem("uploadSizeUploaded") || "0");

    console.log("Started uploading ...");

    const uploadOptions = {
      onProgress: (ev) => {
        uploadSizeToBeUpload.current += blob_size;
        uploadSizeUploaded.current += ev.loadedBytes;
        const percentCompleted = (uploadSizeUploaded.current / uploadSizeToBeUpload.current) * 100;

        localStorage.setItem("uploadSizeToBeUpload", uploadSizeToBeUpload.current.toString());
        localStorage.setItem("uploadSizeUploaded", uploadSizeUploaded.current.toString());

        setUploadMessage(`Uploading ${JSON.parse(localStorage.getItem("totalFilesUploaded") || "0")} of ${JSON.parse(localStorage.getItem("totalFilesToBeUploaded") || "0")} : ${Math.round(percentCompleted)}%`);
      },
      blobHTTPHeaders: {
        blobContentType: blob_type,
      },
      // Add configurable chunk size for better performance on slow connections
      blockSize: 4 * 1024 * 1024, // 4MB chunks
    };

    await blockBlobClient.uploadData(videoFile, uploadOptions);
  };

  // Utility function for exponential backoff retry
  const exponentialRetry = async (fn, maxRetries, initialDelay) => {
    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        return await fn();
      } catch (error) {
        if (attempt === maxRetries - 1) throw error;
        const delay = initialDelay * Math.pow(2, attempt);
        console.log(`Retry attempt ${attempt + 1}/${maxRetries}. Waiting for ${delay}ms before next attempt.`);
        await new Promise(resolve => setTimeout(resolve, delay));
      }
    }
  };
  //Start Speech Recognisation
  const handleSpeechRecognisationStart = () => {
    // microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-IN",
    });
  };
  //Stop Speech Recognisation
  const handleSpeechRecognisationStop = () => {
    // microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();
  };
  //call getquestion function and get camera permission function
  const apicall = async () => {
    await getCameraPermission();
    await getQuestionDetails();
  };
  //Fetch question ids from local storage and get the first one from array, remove the same and store back array.
  const getQuestion = async () => {
    //let next_question = num.replaceAll(num[3], +countQn + 1);
    let interview_question_ids = JSON.parse(
      sessionStorage.getItem("interview_question_ids")
    );
    if (interview_question_ids.length > 0) {
      const current_question_id = interview_question_ids[Number(qNo) - 1];
      // const current_question_id = interview_question_ids.shift();
      currentQuestionId.current = current_question_id?._id;
      sessionStorage.setItem(
        "current_interview_question_id",
        current_question_id?._id
      );
      sessionStorage.setItem(
        "interview_question_ids",
        JSON.stringify(interview_question_ids)
      );
      currentQuestionNo.current = currentQuestionNo.current + 1;
      await apicall();
    } else {
      navigate(`/interview/error`);
    }
  };
  //fetch question details from DB
  const getQuestionDetails = async () => {
    // const data = {
    //   question_id: currentQuestionId.current,
    // };

    setQuestion(questionData.question);
    setAnswer(questionData.question_id);
    setQuestionId(questionData._id);
    setInterviewTimeLeft(questionData.maximum_duration);
    setTextInputRequired(questionData.text_box_required);
    setTextInputAnswer("");
    currentQuestionMaxDuration.current = questionData.maximum_duration;
    //setLoading(false);
    startRecording();

    // Convert the data object to a query string

    // await fetch(`${API}/candidate/interview/get-question`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     authorization: `Bearer ${sessionStorage.getItem("Token_candi")}`,
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (data.success === true) {
    //       setQuestion(data.result.question);
    //       setAnswer(data.result.question_id);
    //       setQuestionId(data.result._id);
    //       setInterviewTimeLeft(data.result.maximum_duration);
    //       setTextInputRequired(data.result.text_box_required);
    //       setTextInputAnswer("");
    //       currentQuestionMaxDuration.current = data.result.maximum_duration;
    //       //setLoading(false);
    //       startRecording();
    //     } else {
    //       toast.error("Unable to fetch question details");
    //       navigate(`/interview/error`);
    //     }
    //   });
  };
  const videoFile = [];
  const videoReducer = (state, action) => {
    switch (action.type) {
      case "SET_VIDEO":
        return action.payload;
      case "CLEAR_VIDEO":
        return [];
      default:
        return state;
    }
  };
  const [videoData, dispatch] = useReducer(videoReducer, videoFile);
  const handleClearVideo = () => {
    dispatch({ type: "CLEAR_VIDEO" });
  };

  let action = [];
  const actionReducer = (state, action) => {
    switch (action.type) {
      case "SET_LOG":
        return action.payload;
      case "CLEAR_LOG":
        return [];
      default:
        return state;
    }
  };
  const handleClearAction = () => {
    dispatch2({ type: "CLEAR_LOG" });
  };
  const [actionData, dispatch2] = useReducer(actionReducer, action);
  // to display the timer in minutes
  const dispSecondsAsMins = (seconds) => {
    // 00:10
    const mins = Math.floor(seconds / 60);
    const seconds_ = seconds % 60;
    return (
      mins.toString() +
      "." +
      (seconds_ === 0
        ? "00"
        : seconds_ <= 9
          ? "0" + seconds_.toString()
          : seconds_.toString())
    );
  };
  //timer reset function
  const handleReset = () => {
    clearInterval(increment.current);
    currentQuestionMaxDuration.current = 0;
  };
  // moving to next question using next button
  const nextButton = () => {
    setNextBtnLoading(true);
    stopRecording();
  };
  // moving to next question function
  const nextQn = async (myFile) => {
    if (Number(qNo) < Number(totalQuestions.current)) {
      getQuestion();
      navigate(`/interview/questions?qno=${Number(qNo) + 1}`);
    } else {
      updateStatus("completed");
      processUploadQueue();
      timer = setInterval(async () => {
        console.log(`showing uplodqueue ref from nextQn line 319 ${uploadQueueRef.current.length}`);
        if (uploadQueueRef.current.length <= 0) {
          userLog = [];
          removeCameraPermission();
          handleSpeechRecognisationStop();
          checkInterviewUploadStatus()
        }
      }, 10000);
    }
  };
  //check upload status and show error to the uer
  const checkInterviewUploadStatus = () => {

    fetch(`${API}/candidate/interview/upload-status`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("Token_candi")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let totalRecords;
        let uploadedRecords;
        totalRecords = data.result.total_records;
        uploadedRecords = data.result.total_uploads
        if (data.success !== true) {
          navigate("/interview/error");
          //toast.error('Something Went Wrong');
        } else {
          console.log(`totalRecords=${totalRecords}&&uploadedRecords=${uploadedRecords}`)
          if (totalRecords === uploadedRecords) {
            navigate("/interview/thankyou");
            clearInterval(timer)
            setLoading(false);
            clearInterval(statusTimer)
          } else {
            navigate(`/interview/upload-error?totalRecords=${totalRecords}&&uploadedRecords=${uploadedRecords}&&interviewUUID=${sessionStorage.getItem("interview_uuid")}`);
          }
        }
      })
      .catch(() => { });
  }
  // get camera permission
  const getCameraPermission = async () => {
    //get video and audio permissions and then stream the result media stream to the videoSrc variable
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true, noiseSuppression: true };
        // create audio and video streams separately
        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );
        //combine both audio and video streams
        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);
        setStream(combinedStream);
        video_get = combinedStream;
        // mediaStream = combinedStream;
        //set videostream to live feed player

        // liveVideoFeed.current = localStorage.getItem('liveVideoFeed')

        liveVideoFeed.current.srcObject = videoStream;
        // localStorage.setItem('liveVideoFeed',liveVideoFeed.current)
      } catch (err) {
        console.error(err.message);
      }
    } else {
      toast.error("The MediaRecorder API is not supported in your browser.");
      navigate("/interview/error");
    }
  };
  let video = [];
  //start video recording
  const startRecording = () => {
    recordingStartDateTime.current = new Date(Date.now());
    if (JSON.parse(qNo) === 1) {
      updateStatus("in_progress");
    }

    setLoading(false);
    let localVideoChunks = [];
    const media = new MediaRecorder(stream !== null ? stream : video_get, {
      mimeType,
    });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localVideoChunks.push(event.data);
    };
    video = localVideoChunks;
    dispatch({ type: "SET_VIDEO", payload: localVideoChunks });

    if (faceDetection) {
      faceMyDetect();
    }

    if (tabDetection) {
      tabDetect();
    }
    handleSpeechRecognisationStart();

    setTimeout(() => {
      setBtnDisable(false);
    }, 5000);

    setProgressBarDisabled(false);
    //check timer and move to next question if times up
    increment.current = setInterval(() => {
      // timeElapsed += 1;
      setTimeElapsed(timeElapsed + 1);

      setInterviewTimeLeft((current) => (current > 0 ? current - 1 : current));
      // if (currentQuestionMaxDuration.current === timeElapsed || timeElapsed >= currentQuestionMaxDuration.current) {
      //   toast.error(Number(totalQuestions.current) === Number(qNo) ? "Time's Up" : "Time's Up. Let's go the next", {
      //     id: 'timer-runout',
      //   });
      //   timeElapsed = 0;
      //   clearInterval(increment.current);
      //   nextButton()
      // }
    }, 1000);
  };
  useEffect(() => {
    if (interviewTimeLeft <= 0) {
      toast.error(
        Number(totalQuestions.current) === Number(qNo)
          ? "Time's Up"
          : "Time's Up. Let's go the next question",
        {
          id: "timer-runout",
        }
      );
      clearInterval(increment.current);
      nextButton();
    }

    //eslint-disable-next-line
  }, [interviewTimeLeft]);
  //load models from face api
  const loadModels = async (val) => {
    //  Promise.all([
    // THIS FOR FACE DETECT AND LOAD FROM YOU PUBLIC/MODELS DIRECTORY
    await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
    await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
    await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
    await faceapi.nets.faceExpressionNet.loadFromUri("/models");
  };
  //facedetection function
  const faceMyDetect = async () => {
    let Timetaken = 0;
    faceDetectionSetIntervalId.current = setInterval(async () => {
      const startDateTime = new Date(Date.now());
      if (
        liveVideoFeed.current != null &&
        liveVideoFeed.current !== undefined
      ) {
        Timetaken += 5;
        const AtTime = dispSecondsAsMins(Timetaken);
        const detections = await faceapi.detectAllFaces(
          liveVideoFeed.current,
          new faceapi.TinyFaceDetectorOptions()
        ); //.withFaceLandmarks().withFaceExpressions();
        // Assuming the first detected face const score = face.detection._score;
        const endDateTime = new Date(Date.now());

        if (detections.length === 0) {
          userLog.push({
            time: AtTime,
            start_second: Timetaken,
            end_second: Timetaken + 5,
            start_date_time: startDateTime,
            total_duration_second: 5,
            end_date_time: endDateTime,
            type: "face",
            action: "Candidate not facing camera",
            action_category: "red",
          });
          dispatch2({ type: "SET_LOG", payload: [...userLog] });
          /*
          toast.error("We detected that you are not properly in front of camera, please be in camera.", {
            id: 'facedetect',
          });*/
          // toast("No face detected!")
        } else if (detections && detections.length === 1) {
          const face = detections[0];
          const score = face._score;
          //TODO total_duration_second is currently static, we need make the same dynamic in next build
          if (score < 0.6) {
            userLog.push({
              time: AtTime,
              type: "face",
              start_second: Timetaken,
              end_second: Timetaken + 5,
              start_date_time: startDateTime,
              total_duration_second: 5,
              end_date_time: endDateTime,
              action: "Candidate not facing camera",
              action_category: "orange",
            });
            //console.log({ time: AtTime, action: "Candidate not facing camera", action_category: "orange" });
            dispatch2({ type: "SET_LOG", payload: [...userLog] });
            /*
            toast.error("We detected that you are moving away from camera, please be in camera.", {
              id: 'facedetect',
            });*/
          }
        } else if (detections && detections.length > 1) {
          userLog.push({
            time: AtTime,
            type: "face",
            start_second: Timetaken,
            end_second: Timetaken + 5,
            start_date_time: startDateTime,
            total_duration_second: 5,
            end_date_time: endDateTime,
            action: "More than one person detected",
            action_category: "red",
          });
          /*
          toast.error("We detected more than one person, we were expecting your full attention.", {
            id: 'facedetect',
          });*/
          dispatch2({ type: "SET_LOG", payload: [...userLog] });
        }
      }
    }, 5000);
  };
  //detect tab switch
  const tabDetect = async () => {
    let Timetaken = 0;
    tabDetectionSetIntervalId.current = setInterval(async () => {
      Timetaken += 1;
      const startDateTime = new Date(Date.now());
      const endDateTime = new Date(startDateTime.getTime() + 1000);
      if (document.visibilityState === "hidden") {
        const AtTime = dispSecondsAsMins(Timetaken);
        userLog.push({
          time: AtTime,
          type: "browser",
          start_second: Timetaken,
          end_second: Timetaken + 1,
          start_date_time: startDateTime,
          end_date_time: endDateTime,
          total_duration_second: 1,
          action: "Candidate switched tab",
          action_category: "red",
        });

        dispatch2({ type: "SET_LOG", payload: [...userLog] });
        //console.log({ time: AtTime, action: "Candidate switched tab", action_category: "red" });
        //dispatch3({ type: 'SET_FOCUS', payload: [...focusLog] });
        /*
        toast.error("We detected that you are switching tabs, please be in focus.", {
          id: 'tabdetect',
        })*/
      }
    }, 1000);
  };
  // stop recording function
  const stopRecording = async (candidate_stopped_interview) => {
    if (candidate_stopped_interview) {
      setStopBtnLoaidng(true);
    } else {
      setBtnDisable(true);
    }
    setProgressBarDisabled(true);
    completedQuestionsCount.current = completedQuestionsCount.current + 1;

    if (tabDetectionSetIntervalId.current) {
      clearInterval(tabDetectionSetIntervalId.current);
    }
    if (faceDetectionSetIntervalId.current) {
      clearInterval(faceDetectionSetIntervalId.current);
    }
    /*
    stream.getTracks().forEach(function (track) {
      track.stop();
    });*/
    setLoading(true);
    handleReset();
    userLog = [];

    mediaRecorder.current.stop();

    mediaRecorder.current.onstop = async () => {
      const videoBlob = new Blob(video.length !== 0 ? video : videoData, {
        type: mimeType,
      });
      console.log(`showing uplodqueue ref from stoprecording line 615 ${uploadQueueRef.current.length}`);
      addToQueue([videoBlob], videoBlob.size, `${questionId}.webm`, "video/webm", questionId, new Date().toISOString(), 0, false);

      try {

        const recordingEndDateTime = new Date(Date.now());
        const totalInterviewDuration = (recordingEndDateTime.getTime() - recordingStartDateTime.current.getTime()) / 1000;
        const calculatedTotalInterviewDuration = totalInterviewDuration - 15;

        const interviewRecordData = {
          question_id: questionId,
          question: question,
          answer_key: answer,
          audio_text: transcript,
          total_interview_duration_seconds: Math.round(calculatedTotalInterviewDuration),
          candidate_answer: textInputAnswer,
          candidate_action_log: actionData,
        };

        await fetch(`${API}/candidate/interview/create-interview-record`, {
          method: "POST",
          body: JSON.stringify(interviewRecordData),
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${sessionStorage.getItem("Token_candi")}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setNextBtnLoading(false);
            setStopBtnLoaidng(false);

            if (data.success !== true) {
              navigate("/interview/error");
            }
          })
          .then(async () => {
            if (candidate_stopped_interview === true) {
              if (totalQuestions.current > qNo) {
                updateStatus("in_complete");
              } else {
                updateStatus("completed");
              }
              processUploadQueue();
              statusTimer = setInterval(async () => {
                console.log(`showing uplodqueue ref from stop recording interview stop line 670 ${uploadQueueRef.current.length}`);
                if (uploadQueueRef.current.length <= 0) {
                  // setLoading(false);
                  userLog = [];
                  removeCameraPermission();
                  handleSpeechRecognisationStop();
                  checkInterviewUploadStatus()
                  // navigate("/interview/thankyou");
                }
              }, 10000);

            } else {
              handleClearVideo();
              handleClearAction();
              userLog = [];
              handleSpeechRecognisationStop();
              resetTranscript();
              nextQn();
            }
          });
      } catch (error) {
        navigate("/interview/error");
        setNextBtnLoading(false);
        setStopBtnLoaidng(false);
      }
    };
  };

  const removeCameraPermission = async () => {
    const videoConstraints = { video: true, audio: false };
    const videoStream = await navigator.mediaDevices.getUserMedia(
      videoConstraints
    );
    videoStream.getTracks().forEach((track) => track.stop());
  };

  //update the schedule table with interview status
  const updateStatus = async (interview_status) => {
    await fetch(`${API}/candidate/interview/update-interview-status`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("Token_candi")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        interview_status: interview_status,
        end_date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      }),
    });
    /*
    .then((res) => res.json())
    .then((res) => console.log(res.success))
    */
  };
  //return html

  console.log("qNo", qNo)


  return (
    <div>
      <>
        <Toaster />
        <Navbar />
        {
          /* {loading ? ( */
          <div
            className={`${loading
              ? "d-flex mt-5 flex-column justify-content-center align-items-center"
              : "d-none"
              }`}
          >
            <div>
              <ProgressBar
                height="110"
                width="130"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="lightgrey"
                barColor={themeColor.theme_primary}
              />
              {uploadMessage && (
                <div className="text-center">
                  <p style={{ fontWeight: "bolder", fontSize: "20px" }}>
                    {uploadMessage}
                  </p>
                </div>
              )}
            </div>
            {uploadMessage ? (
              <div>
                <div className="text-center fs-5 fw-bold">
                  We are uploading your response, please don't refresh this
                  page...
                </div>
              </div>
            ) : (
              <div className="text-center fs-5 fw-bold">
                {" "}
                We are uploading your response, please don't refresh this
                page...
              </div>
            )}
          </div>
          /* ) : (} */
        }
        <div
          className="main-interview-container"
          style={{
            visibility: loading ? "hidden" : "visible",
          }}
        >
          <div
            className="interview-main-question-container"
            style={{
              display: "flex",
              justifyContent: "center",
              background: "#fff",
            }}
          >
            <div className="interview-question-container">
              <div className="w-100">
                <div className="question-label">
                  Question {qNo} of {totalQuestions.current}
                </div>
                <progress
                  id="question"
                  value={qNo}
                  max={totalQuestions.current}
                >
                  {" "}
                  {`${qNo} %`}
                </progress>

                <div
                  className={
                    textInputRequired === true
                      ? "display-question mt-3"
                      : "display-question-show mt-3"
                  }
                >
                  <Interweave content={question} />
                </div>
              </div>

              {textInputRequired === true ? (
                <textarea
                  className="interview-text-input"
                  placeholder="Please type your answer here (If required)"
                  name="answer"
                  rows="7"
                  cols="50"
                  value={textInputAnswer}
                  onChange={(e) => setTextInputAnswer(e.target.value)}
                ></textarea>
              ) : null}
            </div>
          </div>
          <div className="interview-video-container">
            <div className="video-container">
              <div
                style={{
                  width: "100%",
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    marginTop: "5px",
                  }}
                >
                  <button
                    data-toggle="modal"
                    data-target="#myModal"
                    className="stop-button-hover"
                    disabled={btnDisable}
                  >
                    <span style={{ marginLeft: "-5px" }}>
                      <img
                        src={record}
                        className="record-img"
                        alt="red-exclamation"
                        style={{ verticalAlign: "middle", width: 40 }}
                      />
                    </span>
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                      Stop Interview
                    </span>
                  </button>
                  <div
                    style={{
                      height: 40,
                      margin: 5,
                      background: "#33333380 0% 0% no-repeat padding-box",
                      color: "white",
                      borderRadius: 3,
                      opacity: progressBarDisabled ? 0.5 : 1,
                    }}
                  >
                    <span
                      style={{
                        padding: "0px 15px 15px 15px",
                        lineHeight: "2.6",
                      }}
                    >
                      Time left: {dispSecondsAsMins(interviewTimeLeft)}
                    </span>

                    <progress
                      id={
                        Math.round(interviewTimeLeft * 0.5) >=
                          currentQuestionMaxDuration.current &&
                          Math.round(interviewTimeLeft * 0.2) <=
                          currentQuestionMaxDuration.current
                          ? "questionOrange"
                          : Math.round(interviewTimeLeft * 0.2) >=
                            currentQuestionMaxDuration.current
                            ? "questionRed"
                            : "questionGreen"
                      }
                      value={interviewTimeLeft}
                      max={currentQuestionMaxDuration.current}
                      disabled={progressBarDisabled}
                    >
                      {" "}
                      {`${interviewTimeLeft}`}
                    </progress>
                  </div>

                  <button
                    className="next-button-hover"
                    disabled={btnDisable || nextBtnLoading}
                    ref={nextButtonRef}
                    onClick={() => nextButton()}
                  >
                    {nextBtnLoading ? (
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        Loading....
                      </span>
                    ) : (
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        {totalQuestions.current > Number(qNo)
                          ? "Next Question"
                          : "Finish Interview"}
                      </span>
                    )}

                    <span style={{ marginRight: "-5px" }}>
                      <img
                        src={next}
                        className="next-img"
                        alt="next"
                        style={{ verticalAlign: "middle", width: 40 }}
                      />
                    </span>
                  </button>
                </div>
              </div>

              <video
                className="video-preview"
                autoPlay
                playsInline
                ref={liveVideoFeed}
              ></video>
            </div>
          </div>
        </div>
        {/* )} */}
        {/* Modal */}
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog text-center">
            {/* Modal content*/}
            <div
              className="modal-content"
              style={{ borderRadius: 20, padding: "30px 20px" }}
            >
              {/* <button type="button" class="close" data-dismiss="modal" style="margin: 10px;text-align: right;">&times;</button> */}
              <div
                className="modal-header text-center"
                style={{ border: "none" }}
              >
                <img
                  src={Alert}
                  className="img-fluid"
                  alt="modal_image"
                  style={{ width: 100, marginLeft: "40%" }}
                />
              </div>
              {/* <div > */}
              <h3 className="alert-modal-label">
                Are you sure you want to stop the interview?
              </h3>
              <p style={{ lineHeight: "2", marginTop: "5px" }}>
                Once stopped, you will not be able to resume or make any further
                changes. Please confirm your decision.
              </p>
              {/* </div> */}
              <div className="button-container mt-0">
                <button
                  type="submit"
                  data-dismiss="modal"
                  className="btn submit-btn"
                  onClick={() => stopRecording(true)}
                >
                  {stopBtnLoading ? "Loading..." : "Submit"}
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn cancel-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
